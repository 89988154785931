import i18n from '@/base/i18n/index'
import { ToolsClass } from '@/base/class/tools.class'
import Vue from 'vue'

export const PAYWAY_PSD2_TYPES = {
  pis: 'PIS',
  ais: 'AIS'
}

export const PAYWAY_GROUPS_TYPES = {
  promo: 'PROMO',
  auto: 'AUTO',
  blik: 'BLIK',
  blikPayLater: 'BLIK_PAY_LATER',
  card: 'CARD',
  pbl: 'PBL',
  wallet: 'WALLET',
  visaMobile: 'VISAMOBILE',
  installments: 'INSTALLMENTS',
  otp: 'OTP',
  transfer: 'TRANSFER',
  companyPayments: 'COMPANY_PAYMENTS',
  other: 'OTHER'
}

export const PAYWAY_GROUPS_FRONT_API_MAP = {
  'PROMO': PAYWAY_GROUPS_TYPES.promo,
  'AUTO': PAYWAY_GROUPS_TYPES.auto,
  'BLIK': PAYWAY_GROUPS_TYPES.blik,
  'BNPL_BLIK': PAYWAY_GROUPS_TYPES.blikPayLater,
  'CARD': PAYWAY_GROUPS_TYPES.card,
  'PBL': PAYWAY_GROUPS_TYPES.pbl,
  'WALLET': PAYWAY_GROUPS_TYPES.wallet,
  'VISAMOBILE': PAYWAY_GROUPS_TYPES.visaMobile,
  'INSTALLMENTS': PAYWAY_GROUPS_TYPES.installments,
  'BNPL': PAYWAY_GROUPS_TYPES.otp,
  'FR': PAYWAY_GROUPS_TYPES.transfer,
  'B2B': PAYWAY_GROUPS_TYPES.companyPayments
}

export const PAYWAY_GROUPS_ORDER = {
  [PAYWAY_GROUPS_TYPES.promo]: 1,
  [PAYWAY_GROUPS_TYPES.auto]: 2,
  [PAYWAY_GROUPS_TYPES.blik]: 3,
  [PAYWAY_GROUPS_TYPES.card]: 4,
  [PAYWAY_GROUPS_TYPES.pbl]: 5,
  [PAYWAY_GROUPS_TYPES.visaMobile]: 6,
  [PAYWAY_GROUPS_TYPES.wallet]: 7,
  [PAYWAY_GROUPS_TYPES.installments]: 8,
  [PAYWAY_GROUPS_TYPES.blikPayLater]: 9,
  [PAYWAY_GROUPS_TYPES.otp]: 10,
  [PAYWAY_GROUPS_TYPES.companyPayments]: 11,
  [PAYWAY_GROUPS_TYPES.transfer]: 12,
  [PAYWAY_GROUPS_TYPES.other]: 13
}

export const PAYWAY_GROUP_MODEL = {
  id: null,
  type: null,
  label: null,
  description: null,
  tooltip: null,
  icon: null,
  order: null,
  payways: [],
  active: true
}

export const PAYWAY_REQUIRED_PARAMS_NAMES = {
  accountHolderName: 'accountHolderName',
  nip: 'nip'
}

export const PAYWAY_REQUIRED_PARAMS_TYPES = {
  string: 'STRING',
  boolean: 'BOOLEAN',
  currency: 'CURRENCY',
  date: 'DATE',
  integer: 'INTEGER',
  long: 'LONG',
  money: 'MONEY',
  recurringAcceptanceState: 'RECURRING_ACCEPTANCE_STATE',
  recurringAction: 'RECURRING_ACTION',
  transactionSettlementMode: 'TRANSACTION_SETTLEMENT_MODE',
  walletType: 'WALLET_TYPE'
}

export const PAYWAY_REQUIRED_PARAMS_TYPES_MAP = {
  [PAYWAY_REQUIRED_PARAMS_TYPES.string]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.boolean]: 'checkbox',
  [PAYWAY_REQUIRED_PARAMS_TYPES.currency]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.date]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.integer]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.long]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.money]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.recurringAcceptanceState]: 'checkbox',
  [PAYWAY_REQUIRED_PARAMS_TYPES.recurringAction]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.transactionSettlementMode]: 'text',
  [PAYWAY_REQUIRED_PARAMS_TYPES.walletType]: 'text'
}

export const getRequiredParamFieldType = paramType => {
  return PAYWAY_REQUIRED_PARAMS_TYPES_MAP[paramType]
}

export const isRequiredParamFieldTypeCheckbox = paramType => {
  return getRequiredParamFieldType(paramType) === 'checkbox'
}

export const isRequiredParamFieldTypeText = paramType => {
  return ['text', 'date', 'number'].includes(getRequiredParamFieldType(paramType))
}

export const getRequiredParamLabel = paramName => {
  return i18n.t(`requiredParams.${paramName}.label`)
}

export const getRequiredParamFlowLabel = paramName => {
  return i18n.t(`requiredParams.${paramName}.flowLabel`)
}

export const getRequiredParamPlaceholder = paramName => {
  return i18n.t(`requiredParams.${paramName}.placeholder`)
}

export const paywayGroupsList = () => {
  const GROUPS_ORDER = Vue.prototype.$VUE_PAYWAY_GROUPS_ORDER_CONFIG ? Vue.prototype.$VUE_PAYWAY_GROUPS_ORDER_CONFIG : PAYWAY_GROUPS_ORDER
  const PAYWAY_GROUPS = [
    {
      type: PAYWAY_GROUPS_TYPES.auto,
      label: i18n.t('configuration.paywayGroups.auto.label'),
      labelInitWithRefund: i18n.t('configuration.paywayGroups.auto.labelInitWithRefund'),
      labelInitWithRefundOrange: i18n.t('configuration.paywayGroups.auto.labelInitWithRefundOrange'),
      labelInitWithPayment: i18n.t('configuration.paywayGroups.auto.labelInitWithPayment'),
      description: i18n.t('configuration.paywayGroups.auto.description'),
      descriptionInitWithRefund: i18n.t('configuration.paywayGroups.auto.descriptionInitWithRefund'),
      descriptionInitWithPayment: i18n.t('configuration.paywayGroups.auto.descriptionInitWithPayment'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.auto]
    },
    {
      type: PAYWAY_GROUPS_TYPES.blik,
      label: i18n.t('configuration.paywayGroups.blik.label'),
      description: i18n.t('configuration.paywayGroups.blik.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.blik]
    },
    {
      type: PAYWAY_GROUPS_TYPES.card,
      label: i18n.t('configuration.paywayGroups.card.label'),
      description: i18n.t('configuration.paywayGroups.card.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.card]
    },
    {
      type: PAYWAY_GROUPS_TYPES.pbl,
      label: i18n.t('configuration.paywayGroups.pbl.label'),
      description: i18n.t('configuration.paywayGroups.pbl.description'),
      descriptionVerify: i18n.t('configuration.paywayGroups.pbl.descriptionVerify'),
      descriptionServiceVerify: i18n.t('configuration.paywayGroups.pbl.descriptionServiceVerify'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.pbl]
    },
    {
      type: PAYWAY_GROUPS_TYPES.visaMobile,
      label: i18n.t('configuration.paywayGroups.visaMobile.label'),
      description: i18n.t('configuration.paywayGroups.visaMobile.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.visaMobile]
    },
    {
      type: PAYWAY_GROUPS_TYPES.wallet,
      label: i18n.t('configuration.paywayGroups.wallet.label'),
      description: i18n.t('configuration.paywayGroups.wallet.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.wallet]
    },
    {
      type: PAYWAY_GROUPS_TYPES.installments,
      label: i18n.t('configuration.paywayGroups.installments.label'),
      description: null,
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.installments]
    },
    {
      type: PAYWAY_GROUPS_TYPES.otp,
      label: i18n.t('configuration.paywayGroups.otp.label'),
      description: i18n.t('configuration.paywayGroups.otp.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.otp]
    },
    {
      type: PAYWAY_GROUPS_TYPES.blikPayLater,
      label: i18n.t('configuration.paywayGroups.otp.label'),
      description: i18n.t('configuration.paywayGroups.otp.descriptionBlikPayLater'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.otp]
    },
    {
      type: PAYWAY_GROUPS_TYPES.companyPayments,
      label: i18n.t('configuration.paywayGroups.companyPayments.label'),
      description: i18n.t('configuration.paywayGroups.companyPayments.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.companyPayments]
    },
    {
      type: PAYWAY_GROUPS_TYPES.transfer,
      label: i18n.t('configuration.paywayGroups.transfer.label'),
      description: i18n.t('configuration.paywayGroups.transfer.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.transfer]
    },
    {
      type: PAYWAY_GROUPS_TYPES.other,
      label: i18n.t('configuration.paywayGroups.other.label'),
      description: i18n.t('configuration.paywayGroups.other.description'),
      order: GROUPS_ORDER[PAYWAY_GROUPS_TYPES.otp]
    }
  ]
  const groups = []
  PAYWAY_GROUPS.forEach(group => {
    const newGroupModel = ToolsClass.deepExtend(PAYWAY_GROUP_MODEL)
    const newGroup = Object.assign({}, newGroupModel, group)
    newGroup.id = ToolsClass.generateUUID()
    groups.push(newGroup)
  })
  return groups
}

export const AUTO_TYPES = {
  creditCard: {
    id: 1503,
    label: 'Credit Card'
  }
}
export const AUTO_ID_LIST = [
  AUTO_TYPES.creditCard.id
]

export const CARD_TYPES = {
  creditCard: {
    id: 1500,
    label: 'Credit Card'
  }
}
export const CARD_ID_LIST = [
  CARD_TYPES.creditCard.id
]

export const WALLET_TYPES = {
  visaCheckout: {
    id: 1511,
    label: 'Visa Checkout'
  },
  googlePay: {
    id: 1512,
    label: 'Google Pay'
  },
  applePay: {
    id: 1513,
    label: 'Apple Pay'
  },
  masterPass: {
    id: 1505,
    label: 'MasterPass'
  }
}
export const WALLET_ID_LIST = [
  WALLET_TYPES.visaCheckout.id,
  WALLET_TYPES.googlePay.id,
  WALLET_TYPES.applePay.id,
  WALLET_TYPES.masterPass.id
]

export const VISA_MOBILE_TYPES = {
  visaMobile: {
    id: 1523,
    label: 'VisaMobile'
  }
}

export const VISA_MOBILE_ID_LIST = [
  VISA_MOBILE_TYPES.visaMobile.id
]

export const OTP_TYPES = {
  payka: {
    id: 701,
    label: 'Payka',
    icon: 'payka'
  },
  smartney: {
    id: 700,
    label: 'Smartney',
    icon: 'smartney'
  },
  paypo: {
    id: 705,
    label: 'PayPo',
    icon: 'paypo'
  },
  blikPayLater: {
    id: 523,
    label: 'BLIK płacę później',
    icon: 'blikPayLater'
  }
}
export const OTP_ID_LIST = [
  OTP_TYPES.payka.id,
  OTP_TYPES.smartney.id,
  OTP_TYPES.paypo.id,
  OTP_TYPES.blikPayLater.id
]

export const INSTALLMENT_TYPES = {
  alior: {
    id: 1506,
    label: 'Alior'
  }
}

export const INSTALLMENT_ID_LIST = [
  INSTALLMENT_TYPES.alior.id
]

export const COMPANY_PAYMENTS_TYPES = {
  fpay: {
    id: 706,
    label: 'F-Pay'
  }
}

export const COMPANY_PAYMENTS_ID_LIST = [
  COMPANY_PAYMENTS_TYPES.fpay.id
]

export const BACKEND_PAYWAY_TYPES = {
  type1: 'Szybki Przelew',
  type2: 'Płatność automatyczna',
  type3: 'Karta płatnicza',
  type4: 'Kup teraz, zapłać później',
  type5: 'Raty online',
  type6: 'BLIK',
  type7: 'Portfele elektroniczne',
  type8: 'PBC',
  type9: 'PBL',
  type10: 'FR',
  type11: 'PG',
  type12: 'PBI',
  type13: 'PG_PBL'
}

export const PAYWAY_GROUPS_TYPES_MAP = {
  [PAYWAY_GROUPS_TYPES.auto]: [BACKEND_PAYWAY_TYPES.type2],
  [PAYWAY_GROUPS_TYPES.blik]: [BACKEND_PAYWAY_TYPES.type6],
  [PAYWAY_GROUPS_TYPES.card]: [BACKEND_PAYWAY_TYPES.type3, BACKEND_PAYWAY_TYPES.type8],
  [PAYWAY_GROUPS_TYPES.pbl]: [BACKEND_PAYWAY_TYPES.type9, BACKEND_PAYWAY_TYPES.type13, BACKEND_PAYWAY_TYPES.type11],
  [PAYWAY_GROUPS_TYPES.wallet]: [BACKEND_PAYWAY_TYPES.type7],
  [PAYWAY_GROUPS_TYPES.installments]: [BACKEND_PAYWAY_TYPES.type5, BACKEND_PAYWAY_TYPES.type12],
  [PAYWAY_GROUPS_TYPES.otp]: [BACKEND_PAYWAY_TYPES.type4],
  [PAYWAY_GROUPS_TYPES.transfer]: [BACKEND_PAYWAY_TYPES.type1, BACKEND_PAYWAY_TYPES.type10]
}

export const PAYWAY_IMAGES_SRC = '/web/img/payway/'

export const PAYWAY_IMAGES = [
  { img: 'alior.svg', id: [95, 1608] },
  { img: 'bfg.png', id: [98] },
  { img: 'bnp-paribas.png', id: [31, 508, 1035] },
  { img: 'bos.svg', id: [29, 86] },
  { img: 'bs-biala-rawska.png', id: [1520] },
  { img: 'bs-sztum.png', id: [1507] },
  { img: 'citi-handlowy.svg', id: [90] },
  { img: 'credit-agricole.png', id: [59, 1609] },
  { img: 'getin-bank.svg', id: [513] },
  { img: 'giropay.png', id: [1904] },
  { img: 'ing.svg', id: [68, 1602, 1702] },
  { img: 'inteligo.jpg', id: [1064] },
  { img: 'ipko.svg', id: [1063] },
  { img: 'kbs.png', id: [1519] },
  { img: 'mbank.jpg', id: [3, 17, 1600] },
  { img: 'mbs.png', id: [1522] },
  { img: 'millenium.svg', id: [12, 85] },
  { img: 'neo-bank.png', id: [1050] },
  { img: 'nest-bank.svg', id: [517] },
  { img: 'nicolaus-bank.svg', id: [1515] },
  { img: 'noble-bank.png', id: [514] },
  { img: 'pekao.svg', id: [52, 1704] },
  { img: 'plus-bank.jpeg', id: [131] },
  { img: 'pocztowy24.png', id: [108] },
  { img: 'santander.svg', id: [27, 1603] },
  { img: 'sgb-lututow.jpeg', id: [1510] },
  { img: 'toyota.svg', id: [117] },
  { img: 'apple-pay.svg', id: [1513] },
  { img: 'google-pay.svg', id: [1512] },
  { img: 'masterpass.svg', id: [1505] },
  { img: 'visa-checkout.svg', id: [1511] },
  { img: 'blik-place-pozniej.png', id: [523] },
  { img: 'smartney.png', id: [700] },
  { img: 'payka.png', id: [701] },
  { img: 'pay-po.png', id: [705] },
  { img: 'eps.svg', id: [1905] },
  { img: 'bancomat.svg', id: [1906] },
  { img: 'my-bank.svg', id: [1907] },
  { img: 'satispay.svg', id: [1908] },
  { img: 'sepa.svg', id: [1909] },
  { img: 'skrill.svg', id: [1910] },
  { img: 'klarna.svg', id: [1911] },
  { img: 'sofort.svg', id: [1912] },
  { img: 'paysafecard.svg', id: [1913] },
  { img: 'paysafecash.svg', id: [1914] },
  // banki PISowe
  { img: 'alior-PIS.svg', id: [1802] },
  { img: 'bnp-paribas-PIS.png', id: [1804] },
  { img: 'bos-PIS.svg', id: [1813] },
  { img: 'credit-agricole-PIS.png', id: [1809] },
  { img: 'getin-bank-PIS.svg', id: [1801] },
  { img: 'ing-PIS.svg', id: [1808] },
  { img: 'inteligo-PIS.jpg', id: [1810] },
  { img: 'mbank-PIS.jpg', id: [1800] },
  { img: 'millenium-PIS.svg', id: [1807] },
  { img: 'nest-bank-PIS.svg', id: [1814] },
  { img: 'pekao-PIS.svg', id: [1805] },
  { img: 'pko-PIS.svg', id: [1803] },
  { img: 'pocztowy24-PIS.png', id: [1811] },
  { img: 'santander-PIS.svg', id: [1806] },
  { img: 'mbs-PIS.png', id: [] },
  { img: 'bfg-PIS.png', id: [] },
  { img: 'bs-biala-rawska-PIS.png', id: [] },
  { img: 'citi-handlowy-PIS.svg', id: [] },
  { img: 'kbs-PIS.png', id: [] },
  { img: 'nicolaus-bank-PIS.svg', id: [] },
  { img: 'noble-bank-PIS.png', id: [] },
  { img: 'plus-bank-PIS.jpeg', id: [] },
  { img: 'sgb-lututow-PIS.jpeg', id: [] },
  { img: 'toyota-PIS.svg', id: [] }
]

export const singlePaywayWithoutImage = () => {
  return {
    id: 9,
    type: BACKEND_PAYWAY_TYPES.type1,
    text: i18n.t('paywall.paywaySingle.accountAtAnotherBank')
  }
}
