export const ENDPOINTS = {
  start: '/front-api/trx-api/www/start',
  continue: '/front-api/trx-api/www/continue',
  visadata: '/front-api/trx-api/www/visa-mobile/get-transaction-data',
  wait: {
    getData: '/front-api/trx-api/www/wtn-wait/get-data'
  },
  thankYou: {
    getData: '/front-api/trx-api/www/thank-you/get-data',
    update: '/front-api/trx-api/www/thank-you/update'
  },
  regulationsApprovalStart: '/front-api/trx-api/www/regulations-approval/start',
  languageData: '/front-api/trx-api/www/language-data',
  confirmation: '/front-api/trx-api/www/confirmation',
  error: '/front-api/trx-api/www/error',
  reject: '/front-api/trx-api/www/reject',
  updatePayway: '/front-api/trx-api/www/update-payway',
  getPdf: '/front-api/trx-api/www/pdf',
  secureBack: '/front-api/trx-api/www/secure-back',
  appleSession: '/front-api/trx-api/www/apple-pay/session',
  walletData: '/front-api/trx-api/www/google-pay/configuration',
  session: {
    getRemainingTime: '/front-api/trx-api/www/session/get-remaining-time',
    refresh: '/front-api/trx-api/www/session/refresh',
    reject: '/front-api/trx-api/www/session/reject'
  },
  cards: {
    visaMobile: {
      cancel: '/external/gateway/api/visa-mobile/cancel'
    }
  },
  ads: {
    getBannersList: '/ads/get_service_banner/',
    sendBannerViewConfirmation: '/ads/confirm_service_banner/'
  },
  c2p: {
    log: '/external/gateway/api/surveillance/log'
  }
}
