import { Raven } from 'vue-raven'
import { click2PayClass } from '@/base/class/mastercard.click2pay.class'
import {
  CLICK2PAY_CARD_STATUS,
  CLICK2PAY_LOG_LEVEL,
  CLICK2PAY_ORGANIZATION,
  CLICK2PAY_VIEW
} from '@/base/const/click2pay.const'
import { ToolsClass } from '@/base/class/tools.class'
import { apiClass } from '@/base/class/api.class'
import { ENDPOINTS } from '@/base/const/endpoints.const'

const state = {
  loading: false,
  processing: false,
  isSdkInitFinished: false,
  isProcessAvailable: false,
  isProcessActive: false,
  isRecognizedByCookie: null,
  isRecognizedByEmail: null,
  identityValidation: null,
  consumer: {
    emailAddress: null,
    firstName: null,
    lastName: null,
    nationalIdentifier: '',
    mobileNumber: {
      countryCode: null,
      phoneNumber: null
    }
  },
  profiles: null,
  email: null,
  checkoutResult: null,
  view: CLICK2PAY_VIEW.notRecognized,
  selectedCard: null,
  srcCorrelationId: null,
  rememberMe: false,
  idToken: null,
  transaction: {
    amount: null,
    currency: null
  }
}
const mutations = {
  SET_CLICK2PAY_LOADING (state, value) {
    state.loading = value
  },
  SET_CLICK2PAY_PROCESSING (state, value) {
    state.processing = value
  },
  SET_CLICK2PAY_IS_SDK_INIT_FINISHED (state, value) {
    state.isSdkInitFinished = value
  },
  SET_CLICK2PAY_IS_PROCESS_ACTIVE (state, value) {
    state.isProcessActive = value
  },
  SET_CLICK2PAY_IS_PROCESS_AVAILABLE (state, value) {
    state.isProcessAvailable = value
  },
  SET_CLICK2PAY_IS_RECOGNIZED_BY_COOKIE (state, value) {
    state.isRecognizedByCookie = value
  },
  SET_CLICK2PAY_IS_RECOGNIZED_BY_EMAIL (state, value) {
    state.isRecognizedByEmail = value
  },
  SET_CLICK2PAY_IDENTITY_VALIDATION (state, data) {
    state.identityValidation = data
  },
  SET_CLICK2PAY_PROFILES (state, data) {
    state.profiles = data
  },
  SET_CLICK2PAY_EMAIL (state, value) {
    state.email = value
  },
  SET_CLICK2PAY_CHECKOUT_RESULT (state, data) {
    state.checkoutResult = data
  },
  SET_CLICK2PAY_VIEW (state, value) {
    state.view = value
  },
  SET_CLICK2PAY_SELECTED_CARD (state, data) {
    state.selectedCard = data
  },
  SET_CLICK2PAY_SRC_CORRELATION_ID (state, value) {
    state.srcCorrelationId = value
  },
  SET_CLICK2PAY_ID_TOKEN (state, value) {
    state.idToken = value
  },
  SET_CLICK2PAY_TRANSACTION_AMOUNT (state, value) {
    state.transaction.amount = value.toFixed(2)
  },
  SET_CLICK2PAY_TRANSACTION_CURRENCY (state, value) {
    state.transaction.currency = value
  },
  SET_CLICK2PAY_CONSUMER_EMAIL (state, value) {
    state.consumer.emailAddress = value
  },
  SET_CLICK2PAY_CONSUMER_FIRSTNAME (state, value) {
    state.consumer.firstName = value
  },
  SET_CLICK2PAY_CONSUMER_LASTNAME (state, value) {
    state.consumer.lastName = value
  },
  SET_CLICK2PAY_CONSUMER_PHONE_COUNTRYCODE (state, value) {
    state.consumer.mobileNumber.countryCode = '' + value
  },
  SET_CLICK2PAY_CONSUMER_PHONE_PHONENUMBER (state, value) {
    state.consumer.mobileNumber.phoneNumber = value
  },
  SET_CLICK2PAY_REMEMBER_ME (state, value) {
    state.rememberMe = value
  },
  SET_CLICK2PAY_PROCESS_RESET (state) {
    // state.processing = false
    state.isProcessActive = false
    state.isRecognizedByCookie = null
    state.isRecognizedByEmail = null
    state.identityValidation = null
    state.profiles = null
    state.checkoutResult = null
    state.selectedCard = null
    state.srcCorrelationId = null
    state.rememberMe = false
    state.idToken = null
  }
}
const actions = {
  getClick2PayScript ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayScript')
    }
    return new Promise((resolve, reject) => {
      commit('SET_CLICK2PAY_LOADING', true)
      const script = document.createElement('script')
      script.src = process.env.VUE_APP_MASTERCARD_SDK
      script.async = true
      script.addEventListener('load', () => {
        dispatch('logClick2PayTransaction', { context: 'C2P SDK load', message: 'C2P SDK loaded' })
        resolve(void 0)
      })
      script.addEventListener('error', (error) => {
        commit('SET_CLICK2PAY_LOADING', false)
        Raven.captureMessage('click2pay.js getClick2PayScript Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P SDK load', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error, isFatal: true })
        reject(error)
      })
      document.head.appendChild(script)
    })
  },
  getClick2PayInit ({ commit, state, dispatch }, data) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayInit', data)
    }
    return click2PayClass.init(data)
      .then(response => {
        dispatch('logClick2PayTransaction', { context: 'C2P init', message: 'C2P getClick2PayInit success' })
        setTimeout(() => {
          dispatch('getClick2PayIsRecognized').catch(() => {})
        }, 100)
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_CLICK2PAY_LOADING', false)
        commit('SET_CLICK2PAY_IS_PROCESS_AVAILABLE', false)
        Raven.captureMessage('click2pay.js getClick2PayInit Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P init', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error, isFatal: true })
        return Promise.reject(error)
      })
  },
  getClick2PayIsRecognized ({ commit, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayIsRecognized')
    }
    return click2PayClass.isRecognized()
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P isRecognized', message: 'C2P getClick2PayIsRecognized success' })
        commit('SET_CLICK2PAY_IS_RECOGNIZED_BY_COOKIE', click2PayClass.recognized)
        if (click2PayClass.recognized) {
          commit('SET_CLICK2PAY_IS_PROCESS_ACTIVE', true)
          commit('SET_CLICK2PAY_IS_RECOGNIZED_BY_EMAIL', null)
          commit('SET_CLICK2PAY_ID_TOKEN', click2PayClass.idTokens[0])
          dispatch('getClick2PayGetSrcProfile').catch(() => {})
        } else {
          commit('SET_CLICK2PAY_LOADING', false)
          commit('SET_CLICK2PAY_IS_SDK_INIT_FINISHED', true)
        }
        return Promise.resolve()
      })
      .catch(error => {
        commit('SET_CLICK2PAY_LOADING', false)
        commit('SET_CLICK2PAY_IS_PROCESS_AVAILABLE', false)
        commit('SET_CLICK2PAY_IS_SDK_INIT_FINISHED', true)
        Raven.captureMessage('click2pay.js getClick2PayInit Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P isRecognized', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error })
        return Promise.reject(error)
      })
  },
  getClick2PayGetSrcProfile ({ commit, getters, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayGetSrcProfile')
    }
    return click2PayClass.getSrcProfile()
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P getSrcProfile', message: 'C2P getClick2PayGetSrcProfile success' })
        commit('SET_CLICK2PAY_PROFILES', click2PayClass.profiles)
        commit('SET_CLICK2PAY_SRC_CORRELATION_ID', click2PayClass.srcCorrelationId)
        const activeCards = getters.getClick2PayCards
        if (!activeCards) {
          commit('SET_CLICK2PAY_VIEW', CLICK2PAY_VIEW.newCard)
        } else {
          commit('SET_CLICK2PAY_VIEW', CLICK2PAY_VIEW.cardSelected)
        }
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayGetSrcProfile Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P getSrcProfile', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CLICK2PAY_LOADING', false)
        commit('SET_CLICK2PAY_IS_SDK_INIT_FINISHED', true)
      })
  },
  getClick2PayIdentityLookup ({ commit, state, dispatch }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayIdentityLookup')
    }
    return click2PayClass.identityLookup(state.email)
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P identityLookup', message: 'C2P getClick2PayIdentityLookup success' })
        commit('SET_CLICK2PAY_PROCESS_RESET')
        commit('SET_CLICK2PAY_IS_RECOGNIZED_BY_EMAIL', click2PayClass.consumerPresent)
        if (click2PayClass.consumerPresent) {
          commit('SET_CLICK2PAY_IS_PROCESS_ACTIVE', true)
          return dispatch('getClick2PayInitiateIdentityValidation')
        }
        commit('SET_CLICK2PAY_IS_PROCESS_ACTIVE', false)
        commit('SET_CLICK2PAY_LOADING', false)
        return Promise.reject(new Error('Email not registered.'))
      })
      .catch(error => {
        commit('SET_CLICK2PAY_LOADING', false)
        commit('SET_CLICK2PAY_IS_PROCESS_ACTIVE', false)
        Raven.captureMessage('click2pay.js getClick2PayIdentityLookup Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P identityLookup', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error })
        return Promise.reject(error)
      })
  },
  getClick2PayInitiateIdentityValidation ({ commit, dispatch }, validationChannel) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayInitiateIdentityValidation')
    }
    return click2PayClass.initiateIdentityValidation(validationChannel)
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P initiateIdentityValidation', message: 'C2P getClick2PayInitiateIdentityValidation success' })
        commit('SET_CLICK2PAY_IDENTITY_VALIDATION', click2PayClass.identityValidation)
        commit('SET_CLICK2PAY_VIEW', CLICK2PAY_VIEW.codeForm)
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayInitiateIdentityValidation Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P initiateIdentityValidation', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CLICK2PAY_LOADING', false)
      })
  },
  getClick2PayCompleteIdentityValidation ({ commit, dispatch }, validationData) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayCompleteIdentityValidation')
    }
    return click2PayClass.completeIdentityValidation(validationData)
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P completeIdentityValidation', message: 'C2P getClick2PayCompleteIdentityValidation success' })
        commit('SET_CLICK2PAY_ID_TOKEN', click2PayClass.idTokens[0])
        dispatch('getClick2PayGetSrcProfile')
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayCompleteIdentityValidation Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P completeIdentityValidation', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error })
        return Promise.reject(error)
      })
  },
  getClick2PayEnrollCard ({ commit, state, dispatch }, { encryptedCard }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayEnrollCard')
    }
    commit('SET_CLICK2PAY_PROCESSING', true)
    return click2PayClass.enrollCard(encryptedCard, state.idToken)
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P enrollCard', message: 'C2P getClick2PayEnrollCard success' })
        commit('SET_CLICK2PAY_SELECTED_CARD', click2PayClass.maskedCard)
        commit('SET_CLICK2PAY_SRC_CORRELATION_ID', click2PayClass.srcCorrelationId)
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayEnrollCard Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P enrollCard', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error, isFatal: true })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CLICK2PAY_PROCESSING', false)
      })
  },
  getClick2PayCheckout ({ commit, state, dispatch }, locale) {
    commit('SET_CLICK2PAY_PROCESSING', true)
    const data = {
      srcDigitalCardId: state.selectedCard.srcDigitalCardId,
      dpaTransactionOptions: {
        customInputData: {
          'com.mastercard.dcfExperience': 'WITHIN_CHECKOUT'
        },
        dpaLocale: locale,
        isGuestCheckout: false,
        confirmPayment: false,
        consumerNationalIdentifierRequested: false,
        dpaAcceptedBillingCountries: [],
        dpaAcceptedShippingCountries: [],
        consumerEmailAddressRequested: true,
        consumerNameRequested: true,
        consumerPhoneNumberRequested: true,
        dpaShippingPreference: 'NONE',
        dpaBillingPreference: 'NONE',
        paymentOptions: {
          dpaDynamicDataTtlMinutes: 15,
          dynamicDataType: 'CARD_APPLICATION_CRYPTOGRAM_SHORT_FORM'
        }
      },
      complianceSettings: {
        complianceResources: [
          {
            complianceType: 'TERMS_AND_CONDITIONS',
            uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/terms.html',
            version: 'LATEST'
          },
          {
            complianceType: 'PRIVACY_POLICY',
            uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
            version: 'LATEST'
          }
        ]
      },
      windowRef: document.querySelector('#click2payIframe').contentWindow
    }
    if (state.rememberMe) {
      data['complianceSettings']['complianceResources'].push({
        complianceType: 'REMEMBER_ME',
        uri: 'https://www.mastercard.com/global/click-to-pay/country-listing/privacy.html',
        version: 'LATEST'
      })
    }
    if (state.idToken) {
      data.idToken = state.idToken
    } else {
      data.srciActionCode = 'NEW_USER'
      data.consumer = state.consumer
    }
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayCheckout', data)
    }
    return click2PayClass.checkout(data)
      .then(() => {
        dispatch('logClick2PayTransaction', { context: 'C2P checkout', message: 'C2P_CHECKOUT_SUCCESS' })
        commit('SET_CLICK2PAY_CHECKOUT_RESULT', click2PayClass.checkoutResult)
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayCheckout Method response error', {
          extra: error
        })
        dispatch('logClick2PayTransaction', { context: 'C2P checkout', message: error, logLevel: CLICK2PAY_LOG_LEVEL.error, isFatal: true })
        return Promise.reject(error)
      })
      .finally(() => {
        commit('SET_CLICK2PAY_PROCESSING', false)
      })
  },
  getClick2PayUnbindAppInstance ({ state }) {
    if (ToolsClass.isDebugEnabled()) {
      console.log('getClick2PayUnbindAppInstance')
    }
    return click2PayClass.unbindAppInstance(state.idToken)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        Raven.captureMessage('click2pay.js getClick2PayUnbindAppInstance Method response error', {
          extra: error
        })
        return Promise.reject(error)
      })
  },
  logClick2PayTransaction ({ rootState }, { context = '', message = '', logLevel = CLICK2PAY_LOG_LEVEL.info, isFatal = false, organization = CLICK2PAY_ORGANIZATION.mastercard }) {
    if (!ToolsClass.isProduction()) {
      return Promise.resolve()
    }

    const data = {
      idTransRemote: rootState.payment?.paymentData?.transRemoteID,
      loglevel: logLevel,
      message: JSON.stringify(message),
      browser: window.navigator.userAgent,
      context: context,
      fatal: isFatal,
      organization: organization
    }
    return apiClass.postCards(ENDPOINTS.c2p.log, data)
      .then(() => {
        return Promise.resolve()
      })
      .catch(error => {
        return Promise.reject(error)
      })
  }
}
const getters = {
  getClick2PayUserProfile (state) {
    if (!state.profiles || !state.profiles.length) {
      return false
    }
    return state.profiles[0]
  },
  getClick2PayCards (state, getters) {
    const profile = getters.getClick2PayUserProfile
    if (!profile) {
      return false
    }
    if (!profile.maskedCards.length) {
      return false
    }
    const cardStatuses = [
      CLICK2PAY_CARD_STATUS.active,
      CLICK2PAY_CARD_STATUS.pending
    ]
    let activeCards = profile.maskedCards.filter(card => cardStatuses.includes(card.digitalCardData.status))
    if (!activeCards.length) {
      return false
    }
    activeCards = ToolsClass.sortArrayByObjectProperty(activeCards, 'dateOfCardLastUsed')
    return activeCards.reverse()
  }
}

export default {
  state,
  mutations,
  actions,
  getters
}
