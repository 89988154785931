import { REGEXPS } from '@/base/const/regexp.const'

const files = require.context('.', false, /validator\.js$/)
const modules = {
  required: (value) => (!!value || value === 0),
  requiredPayway: (value) => (!!value || value === 0),
  requiredCreditCard: (value) => (!!value || value === 0),
  requiredVisaMobile: (value) => (!!value || value === 0),
  requiredConsent: (value) => (!!value || value === 0),
  requiredTerms: (value) => (!!value || value === 0),
  maxlength: (value, el) => {
    if (!value || !el) {
      return true
    }
    return value.length <= el.getAttribute('maxlength')
  },
  pattern: (value, el) => {
    if (!value || !el) {
      return true
    }
    const pattern = el.getAttribute('pattern')
    if (!pattern || !pattern.length) {
      return true
    }
    return new RegExp(pattern).test(value)
  },
  email: (value) => {
    if (!value) {
      return true
    }
    if (typeof value !== 'string') {
      return false
    }
    return REGEXPS.emailValidator.test(value)
  }
}

files.keys().forEach((key) => {
  if (key === './index.js') return
  modules[key.replace(/(\.\/|\.validator\.js)/g, '')] = files(key).default
})

export default modules
