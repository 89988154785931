import { ENV } from '@/base/const/env.const'
import { MOBILE_EVENT_NAMES } from '@/base/const/mobileEvents.const'
import { WALLET_TYPES } from '@/base/const/payway.const'

export class ToolsClass {
  /**
   * @param value
   * @param dest
   * @param key
   * @returns {*}
   */
  static deepExtend (value, dest, key) {
    if (value instanceof Array) {
      const newArr = []
      value.forEach((arrValue, index) => {
        this.deepExtend(arrValue, newArr, index)
      })
      addResult(newArr)
    } else if (value instanceof Date) {
      addResult(new Date(value))
    } else if (value instanceof Object) {
      const newObj = {}
      for (const key2 in value) {
        this.deepExtend(value[key2], newObj, key2)
      }
      addResult(newObj)
    } else {
      addResult(value)
    }
    return dest

    function addResult (result) {
      if (typeof dest === 'undefined') {
        dest = result
      } else {
        dest[key] = result
      }
    }
  }

  /**
   * @param {object} elem
   * @param {number} [offset]
   * @returns {Promise<unknown>}
   */
  static scrollToElementTop (elem, offset = 0) {
    if (!window || !window.scrollTo || !elem) {
      return
    }

    const rect = elem.getBoundingClientRect()
    const targetPosition = rect.top + window.scrollY + offset
    window.scrollTo({
      top: targetPosition,
      behavior: 'smooth'
    })

    return new Promise(resolve => {
      const scrollHandler = () => {
        if (window.scrollY === targetPosition) {
          window.removeEventListener('scroll', scrollHandler)
          resolve()
        }
      }
      window.addEventListener('scroll', scrollHandler)
    })
  }

  /**
   * @param {string|number} value
   * @param {array} numerals
   * @param {boolean} [wovalue]
   * @returns {string}
   */
  static pluralize (value, numerals, wovalue) {
    const t0 = value % 10
    const t1 = value % 100
    const vo = []
    if (wovalue !== true) {
      vo.push(value)
    }
    if (value === 1 && numerals[1]) {
      vo.push(numerals[1])
    } else if ((value === 0 || (t0 >= 0 && t0 <= 1) || (t0 >= 5 && t0 <= 9) || (t1 > 10 && t1 < 20)) && numerals[0]) {
      vo.push(numerals[0])
    } else if (((t1 < 10 || t1 > 20) && t0 >= 2 && t0 <= 4) && numerals[2]) {
      vo.push(numerals[2])
    }
    return vo.join(' ')
  }

  /**
   * @returns {boolean}
   */
  static isProduction () {
    return process.env.NODE_ENV === ENV.production
  }

  /**
   * @returns {boolean}
   */
  static isDebugEnabled () {
    return process.env.VUE_APP_DEBUG === 'true'
  }

  /**
   * @returns {boolean}
   */
  static isApiMockEnabled () {
    return process.env.VUE_APP_USE_API_MOCK === 'true'
  }

  /**
   * @returns {boolean}
   */
  static isMobileSDKMockEnabled () {
    return process.env.VUE_APP_USE_MOBILE_SDK_MOCK === 'true'
  }

  /**
   * @returns {boolean}
   */
  static isMobileAppConnection () {
    return (ToolsClass.isAndroid() || ToolsClass.isIOS(MOBILE_EVENT_NAMES.transactionFinishCallback)) && ToolsClass.isVersionSDKSet()
  }

  /**
   * @returns {boolean}
   */
  static isScreenTypeSet () {
    return !!ToolsClass.getQueryParams().screenType
  }

  /**
   * @returns {string}
   */
  static getScreenType () {
    return ToolsClass.getQueryParams().screenType
  }

  /**
   * @returns {boolean}
   */
  static isVersionSDKSet () {
    return typeof window.versionSDK !== 'undefined'
  }

  /**
   * @returns {string}
   */
  static getVersionSDK () {
    return window.versionSDK
  }

  /**
   * @param {string} version
   */
  static setVersionSDK (version) {
    window.versionSDK = version
  }

  /**
   * @returns {boolean}
   */
  static isAndroid () {
    return typeof window.PAYBM_MOBILE !== 'undefined'
  }

  /**
   * @param {string} eventName
   * @returns {boolean}
   */
  static isIOS (eventName) {
    const webkit =
      window.webkit &&
      window.webkit.messageHandlers &&
      window.webkit.messageHandlers[eventName] &&
      window.webkit.messageHandlers[eventName].postMessage

    return typeof webkit !== 'undefined'
  }

  /**
   * @returns {boolean}
   */
  static isIframe () {
    return window.self !== window.top
  }

  /**
   * @param {string} url
   */
  static goToExternalUrl (url) {
    if (ToolsClass.isMobileAppConnection()) {
      const versionSDKParam = `VersionSDK=${ToolsClass.getVersionSDK()}`
      const urlArray = url.split('?')
      if (urlArray.length === 1) {
        url = `${url}?${versionSDKParam}`
      } else {
        const queryParams = urlArray[1].split('&')
        queryParams.push(versionSDKParam)
        urlArray[1] = queryParams.join('&')
        url = urlArray.join('?')
      }
    }
    document.location.href = url
  }

  /**
   * Metoda sprawdzająca dostępność GooglePay i ApplePay na urządzeniu
   * @param {number} paywayId
   * @returns {boolean}
   */
  static isWalletPaywayAvailable (paywayId) {
    if (![WALLET_TYPES.googlePay.id, WALLET_TYPES.applePay.id].includes(paywayId)) {
      return true
    }
    if (ToolsClass.isWalletGooglePay(paywayId) && !window.isSecureContext) {
      return false
    }
    if (ToolsClass.isWalletApplePay(paywayId)) {
      let result
      try {
        result = window.ApplePaySession && window.ApplePaySession.canMakePayments()
      } catch (e) {
        result = false
      }
      if (!result) {
        return false
      }
    }
    return true
  }

  /**
   * Metoda sprawdzająca, czy dany kanał płatności to GooglePay
   * @param {number} paywayId
   * @returns {boolean}
   */
  static isWalletGooglePay (paywayId) {
    return paywayId === WALLET_TYPES.googlePay.id
  }

  /**
   * Metoda sprawdzająca, czy dany kanał płatności to ApplePay
   * @param {number} paywayId
   * @returns {boolean}
   */
  static isWalletApplePay (paywayId) {
    return paywayId === WALLET_TYPES.applePay.id
  }

  /**
   * Metoda usuwająca tagi HTML ze stringa
   * @param {string} text
   * @returns {string}
   */
  static stripHtmlTags (text) {
    if (typeof text !== 'string') {
      return ''
    }
    return text.replace(/(<([^>]+)>)/gi, '')
  }

  /**
   * Metoda do generowania UUID
   * @returns {string}
   */
  static generateUUID () {
    const s4 = () => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1)
    }
    // return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4()
  }

  /**
   * Metoda do konwersji kwoty na liczbę całkowitą
   * @param {number} amount
   * @returns {boolean|number}
   */
  static getAmountAsCurrencyInteger (amount) {
    if (typeof amount !== 'number') {
      return false
    }
    return Math.round(amount * 100)
  }

  /**
   * Metoda do iteracji zarówno po tablicach jak i obiektach
   * @param {array|object} iterable
   * @param {function} callback
   * @returns {void}
   */
  static each (iterable, callback) {
    if (typeof iterable !== 'object') {
      return
    }
    if (iterable instanceof Array) {
      iterable.forEach((value, index, array) => {
        callback(value, index, array)
      })
    } else {
      Object.keys(iterable).forEach(key => {
        callback(iterable[key], key, iterable)
      })
    }
  }

  /** @returns object **/
  static getQueryParams () {
    const params = {}
    window.location.search.slice(1).split('&').forEach(param => {
      if (!param || param.indexOf('=') < 0) {
        return
      }
      const queryParamArr = param.split('=')
      params[queryParamArr[0]] = queryParamArr[1]
    })
    return params
  }

  /**
   * Metoda sortująca tablicę na podstawie wartości pola obiektu
   * @param {array} data
   * @param {string} property
   * @returns {*}
   */
  static sortArrayByObjectProperty (data, property) {
    if (!Array.isArray(data) || typeof property !== 'string') {
      throw new Error('Invalid input: data must be an array and property must be a string')
    }
    const sortedData = [...data] // create a copy of the array
    if (!(property in sortedData[0])) {
      return sortedData
    }
    return sortedData.sort((a, b) => {
      if (typeof a[property] !== 'object' && typeof b[property] !== 'object') {
        return a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0
      } else {
        // Handle non-primitive values
        if (typeof a[property] === 'object' && typeof b[property] === 'object') {
          // Use a more efficient comparison method for object values
          return sortedData
        } else {
          return typeof a[property] === 'object' ? 1 : -1
        }
      }
    })
  }

  /**
   * Metoda blokująca skróty klawiszowe odświeżające stronę
   */
  static disableBrowserRefresh () {
    document.onkeydown = function (e) {
      if (e.key === 'F5') e.preventDefault()
    }
  }

  /**
   * Metoda czyszcząca numer telefonu ze zbędnych znaków
   * @param phone
   * @returns {string}
   */
  static cleanPhoneNumber (phone) {
    if (!phone) {
      return ''
    }
    return String(phone).replace(/-/g, '').replace(/ /g, '')
  }

  /**
   * Metoda sprawdzająca czy przeglądarka to Safari
   * @returns {boolean}
   */
  static isSafariBrowser () {
    return /^((?!chrome|android|crios|fxios).)+safari/i.test(navigator.userAgent)
  }

  /**
   * Metoda sprawdzająca rozmiar okna
   * @returns {boolean}
   */
  static isMobileScreenSize () {
    return window.innerWidth <= 768
  }

  /**
   * Sprawdzenie czy element DOM jest widoczny na ekranie
   * @param {object} element
   * @returns {boolean}
   */
  static isElementInViewport (element) {
    const rect = element.getBoundingClientRect()
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    )
  }

  static getRandomInt (min, max) {
    const minCeiled = Math.ceil(min)
    const maxFloored = Math.floor(max)
    return Math.floor(Math.random() * (maxFloored - minCeiled + 1) + minCeiled)
  }
}
