<template>
  <section class="back-to-shop">
    <button type="button"
            ref="backButton"
            class="btn button-back-to-shop"
            :title="$t('confirmation.linkBack')"
            @click="supportButtonBackClick">
      {{ $t('confirmation.linkBack') }}
    </button>
    <p>{{ $t('confirmation.linkBackTimeout') }} <b>{{redirectTime}} sekund</b></p>
    <input type="hidden" style="margin: 10px;" id="iframefocus" value="">
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'
import Vue from 'vue'
import { CONFIRMATION_REDIRECT_TIME } from '@/base/const/confirmation.const'
import {
  MATOMO_EVENT_CATEGORY,
  MATOMO_EVENT_TYPE
} from '@/base/const/matomo.const'
import { MatomoClass } from '@/base/class/matomo.class'

export default {
  name: 'PartnerReturnBtn',
  data () {
    return {
      redirectHandler: null,
      redirectTime: Vue.prototype.$VUE_CONFIRMATION_TIMER ? Vue.prototype.$VUE_CONFIRMATION_TIMER : CONFIRMATION_REDIRECT_TIME
    }
  },
  computed: {
    ...mapState({
      returnUrl: state => state.confirmation.data.returnUrl || ''
    })
  },
  methods: {
    supportButtonBackClick () {
      this.sendEventButtonClickToMatomo()
      this.goBackToShop()
    },
    goBackToShop () {
      this.stopRedirectTimeout()
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(this.returnUrl)
    },
    stopRedirectTimeout () {
      if (this.redirectHandler) {
        clearTimeout(this.redirectHandler)
      }
    },
    startRedirectTimeout () {
      this.redirectHandler = setTimeout(this.checkRedirectTimeout, 1000)
    },
    checkRedirectTimeout () {
      if (this.redirectTime === 1) {
        this.goBackToShop()
        return
      }
      this.redirectTime--
      this.startRedirectTimeout()
    },
    sendEventButtonClickToMatomo () {
      const event = [
        MATOMO_EVENT_TYPE.trackEvent,
        MATOMO_EVENT_CATEGORY.experiments,
        'trackThankYouPageButtonBackClick'
      ]
      MatomoClass.sendEvent(event)
    },
    sendEventButtonViewToMatomo () {
      const event = [
        MATOMO_EVENT_TYPE.trackEvent,
        MATOMO_EVENT_CATEGORY.experiments,
        'trackThankYouPageButtonBackView'
      ]
      MatomoClass.sendEvent(event)
    }
  },
  mounted () {
    this.startRedirectTimeout()
    const survey = document.getElementById('yourcx.insert')
    if (survey) {
      survey.addEventListener('mouseover', () => {
        this.stopRedirectTimeout()
      })
      survey.addEventListener('mouseout', () => {
        this.startRedirectTimeout()
      })
    }
    this.$refs['backButton'].addEventListener('focus', () => {
      this.stopRedirectTimeout()
    })
    this.sendEventButtonViewToMatomo()
  }
}
</script>
