import { ToolsClass } from '@/base/class/tools.class'

export const HTTP_STATUS = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  RATE_LIMIT_EXCEEDED: 429,
  INTERNAL_SERVER_ERROR: 500,
  SERVICE_UNAVAILABLE: 503
}

export const RATE_LIMIT_EXCEEDED_TIMEOUT = () => ToolsClass.getRandomInt(11, 15) * 1000
