<template>
  <div aria-label="Banner reklamowy w formie grafiki" v-if="banner">
    <a v-if="banner.targetUrl" :href="banner.targetUrl" aria-label="Przejście na stronę reklamy" target="_blank"
       class="ad-banner">
      <img class="banner" :src="banner.imgSrc" :title="banner.title" :alt="banner.alt">
    </a>
    <div v-if="!banner.targetUrl" class="ad-banner">
      <img class="banner" :src="banner.imgSrc" :title="banner.title" :alt="banner.alt">
    </div>
    <div v-if="isBannerExtraContentSet()" v-html="getBannerExtraContent()"></div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { ADS_BANNER_SIZE } from '@/base/const/ads.const'
import base64 from 'base-64'

export default {
  name: 'AdsBanner',
  props: {
    serviceId: {
      type: String,
      required: true
    },
    paywayId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      confirmed: state => state.ads.confirmed
    }),
    ...mapGetters({
      banner: 'getBannerToDisplay'
    })
  },
  methods: {
    ...mapMutations(['SET_ADS_SIZE']),
    ...mapActions([
      'getAdsBanners',
      'sendAdsBannerViewConfirmation'
    ]),
    setWindowSize () {
      if (ToolsClass.isMobileScreenSize()) {
        this.SET_ADS_SIZE(ADS_BANNER_SIZE.mobile)
        return
      }
      this.SET_ADS_SIZE(ADS_BANNER_SIZE.desktop)
    },
    isBannerExtraContentSet () {
      return this.banner.extraContext && this.banner.extraContext.length
      // return true
    },
    getBannerExtraContent () {
      return base64.decode(this.banner.extraContext)
    },
    isBannerExtraHeadContentSet () {
      return this.banner.extraHeadContext && this.banner.extraHeadContext.length
    },
    getBannerExtraHeadContent () {
      return base64.decode(this.banner.extraHeadContext)
    },
    loadExternalScriptsToHeader (url) {
      const script = document.createElement('script')
      script.src = url
      script.async = true
      document.head.appendChild(script)
    },
    sendViewConfirmation () {
      if (!this.banner) {
        return
      }
      if (!!this.confirmed && this.confirmed === this.banner.creationId) {
        return
      }
      const bannerElement = document.querySelector('.ad-banner')
      if (!bannerElement) {
        return
      }
      if (!ToolsClass.isElementInViewport(bannerElement)) {
        return
      }
      const data = {
        serviceId: this.serviceId,
        creationId: this.banner.creationId
      }
      this.sendAdsBannerViewConfirmation(data).catch(() => {
      })
    }
  },
  created () {
    this.setWindowSize()
    const data = {
      serviceId: this.serviceId,
      paywayId: this.paywayId
    }
    this.getAdsBanners(data)
      .then(() => {
        setTimeout(() => {
          if (this.isBannerExtraHeadContentSet()) {
            const bannerExtraHeadContent = this.getBannerExtraHeadContent()
            if (bannerExtraHeadContent && bannerExtraHeadContent.length) {
              const scriptsUrlList = bannerExtraHeadContent.split('|')
              if (scriptsUrlList && scriptsUrlList.length > 0) {
                scriptsUrlList.forEach((script) => {
                  this.loadExternalScriptsToHeader(script)
                })
              }
            }
          }
        }, 100)
      })
      .catch(() => {
      })
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.setWindowSize()
      this.sendViewConfirmation()
    })
    window.addEventListener('scroll', () => {
      this.sendViewConfirmation()
    })
  },
  updated () {
    this.sendViewConfirmation()
  }
}
</script>
