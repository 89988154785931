import { FormClass } from '@/base/class/form.class'
import { ToolsClass } from '@/base/class/tools.class'

export default {
  methods: {
    scrollToError () {
      setTimeout(() => {
        const errorElement = document.querySelector('.is-error')
        if (errorElement) {
          ToolsClass.scrollToElementTop(errorElement, -20)
          const alert = errorElement.querySelector('[role="alert"]')
          if (alert) {
            alert.focus()
          }
        }
      }, 500)
    },
    isFormSubmitted () {
      return FormClass.isFormSubmitted(this.form)
    },
    isErrorForField (fieldName) {
      return FormClass.showErrorForField(fieldName, this.form)
    },
    addErrorToField (fieldName, validatorName, error = true) {
      return FormClass.addErrorToField(error, validatorName, fieldName, this.form)
    },
    getFieldErrorAriaLabel (fieldName) {
      const labels = {
        email: this.$t('messages.validatorsAria.emailNotValid'),
        emailRequired: this.$t('messages.validatorsAria.emailRequired'),
        emailNotRecognized: this.$t('messages.validatorsAria.emailNotRecognized'),
        emailIdentityLookup: this.$t('messages.validatorsAria.emailIdentityLookup'),
        emailLocked: this.$t('messages.validatorsAria.emailLocked'),
        required: this.$t('messages.validatorsAria.required'),
        pattern: this.$t('messages.validatorsAria.pattern'),
        requiredPayway: this.$t('messages.validatorsAria.paywayRequired'),
        requiredCreditCard: this.$t('messages.validatorsAria.creditCardRequired'),
        requiredVisaMobile: this.$t('messages.validatorsAria.phoneRequired'),
        codeNotValid: this.$t('messages.validatorsAria.codeNotValid'),
        codeAccountLocked: this.$t('messages.validatorsAria.codeAccountLocked')
      }
      const error = FormClass.getErrorForField(fieldName, this.form)
      return labels[error] || error
    },
    getFieldErrorText (fieldName) {
      const errors = {
        email: this.$t('messages.validators.emailNotValid'),
        emailRequired: this.$t('messages.validators.emailRequired'),
        emailNotRecognized: this.$t('messages.validators.emailNotRecognized'),
        emailIdentityLookup: this.$t('messages.validators.emailIdentityLookup'),
        emailLocked: this.$t('messages.validators.emailLocked'),
        required: this.$t('messages.validators.required'),
        pattern: this.$t('messages.validators.pattern'),
        requiredPayway: this.$t('messages.validators.paywayRequired'),
        requiredCreditCard: this.$t('messages.validators.creditCardRequired'),
        requiredVisaMobile: this.$t('messages.validators.phoneRequired'),
        requiredConsent: this.$t('messages.validators.requiredConsent'),
        requiredTerms: this.$t('messages.validators.regulationRequired'),
        codeNotValid: this.$t('messages.validators.codeNotValid'),
        codeAccountLocked: this.$t('messages.validators.codeAccountLocked')
      }
      const error = FormClass.getErrorForField(fieldName, this.form)
      return errors[error] || error
    }
  }
}
